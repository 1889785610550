import {IBaseInvoice} from "@/core/entity/IBaseInvoice";

export interface IAetna extends IBaseInvoice {
  anesthesiaMinute: number;
  anesthesiaUnit: number;
  cobInd: string;
  medicaidPaidAmt: number
}

export class ModelAetna implements IAetna {
  diag1 = '';
  diag2 = '';
  diag3 = '';
  diag4: string = '';
  federalTaxNum: string = '';
  anesthesiaMinute: number = NaN;
  anesthesiaUnit: number = NaN;
  medicaidPaidAmt: number = NaN;
  cobInd: string = '';
  patientAccNumber: string = '';
  proc1: string = '';
  proc2: string = '';
  proc3: string = '';
  proc4: string = '';
  type: string = 'AETNA';
}