import {IBaseLine} from "@/core/entity/IBaseLine";

export interface IAetnaLine extends IBaseLine {
  cobAdj: number;
  cobPaid: number
  medicaidPaid: number
  ndc: number
  ndcQl: number
}

export class AetnaLineModel implements IAetnaLine {
  cobAdj: number = 0;
  cobPaid: number = 0;
  medicaidPaid: number = 0;
  ndc: number = 0;
  ndcQl: number = 0;
  amount: number = 0;
  cpt: string = '';
  desc: string = '';
  diagnosis: string = '';
  emg: string = '';
  fromDt: number = NaN;
  modifier: string = '';
  pos: string = '';
  repriced: number = 0;
  rev: string = '';
  toDt: number = NaN;
  type: string = 'AETNA';
  units: number = 0;

  constructor(amount = 0) {
    this.amount = amount;
  }
}

